import React, { useState } from 'react';
import '../../Styles/CategoriesNavbar.css';
import dropdown from '../../Assets/Dropdown.svg';
import { Items } from '../../Assets/Catalogue';

export const CategoriesNavbar = () => {
  let sections = document.querySelectorAll('section');
  let navLinks = document.querySelectorAll('header nav a');

  const filterByCategory = category => {
    return Items.filter(item => item.category.includes(category));
  };

  const fertilizers = filterByCategory('Fertilizers');
  const soilAdditives = filterByCategory('Soil Additives');

  window.onscroll = () => {
    sections.forEach(sec => {
      let top = window.scrollY;
      let offset = sec.offsetTop - 150;
      let height = sec.offsetHeight;
      let id = sec.getAttribute('id');
      if (top >= offset && top < offset + height) {
        navLinks.forEach(links => {
          links.classList.remove('active');
          document
            .querySelector('header nav a[href*=' + id + ']')
            .classList.add('active');
        });
      }
    });
  };

  const [openDropdown, setOpenDropdown] = useState(false);

  return (
    <div className="navBarWrapper">
      <div onClick={() => setOpenDropdown(!openDropdown)} className="dropdown">
        <div>All Categories</div>
        <img
          alt="dropdown"
          src={dropdown}
          style={{ paddingLeft: '0.25rem' }}
        ></img>
        {openDropdown && (
          <div className="dropdownBlock">
            <a href="#Fertilizers" className="menuItem">
              <p>Fertilizers</p>
              <div className="numberofProduct"> {fertilizers.length} </div>
            </a>
            <a href="#SoilAdditives" className="menuItem">
              <p>Soil Additives</p>
              <div className="numberofProduct"> {soilAdditives.length} </div>
            </a>
          </div>
        )}
      </div>

      <nav>
        <a href="#Fertilizers">Fertilizers</a>
        <a href="#SoilAdditives">Soil Additives</a>
      </nav>
    </div>
  );
};
