import React from 'react';
import visa from '../../Assets/visa.svg';
import mastercard from '../../Assets/mastercard.svg';
import rupay from '../../Assets/rupay.svg';
import netbanking from '../../Assets/bhim-1.svg';
import bhim from '../../Assets/bhim.svg';
import lock from '../../Assets/svgexport-7.svg';
import styles from './PaymentMethodFooter.module.scss';
export const PaymentMethodFooter = () => {
  return (
    <div className={styles.paymentMethodFooter}>
      <div className={styles.paymentMethods}>
        <img
          alt="payment methods"
          className={styles.paymentImage}
          src={bhim}
        ></img>
        <img
          alt="payment methods"
          className={styles.paymentImage}
          src={visa}
        ></img>
        <img
          alt="payment methods"
          className={styles.paymentImage}
          src={netbanking}
        ></img>
        <img
          alt="payment methods"
          className={styles.paymentImage}
          src={mastercard}
        ></img>
        <img
          alt="payment methods"
          className={styles.paymentImage}
          src={rupay}
        ></img>
      </div>
      <div className={styles.securedTransaction}>
        <img src={lock} className={styles.lockImage}></img>
        <div>100% secured payments</div>
      </div>
    </div>
  );
};
