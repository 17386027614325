import React from 'react';
import backButton from '../../Assets/BackButton.svg';
import { useNavigate } from 'react-router';

export const BackButton = () => {
  const navigate = useNavigate();
  return (
    <button
      style={{
        display: 'flex',
        alignItems: 'center',
        border: 'none',
        backgroundColor: 'white',
        justifyContent: 'center',
        width: '1rem',
        marginRight: '0.75rem',
      }}
      onClick={() => {
        navigate(-1);
      }}
    >
      <img alt="Pack to previous page" src={backButton}></img>
    </button>
  );
};
