import React, { useEffect, useState } from 'react';
import './bee.scss';
import Bee from '../../Assets/BeeAnimation.gif';

export const BeeFlying = () => {
  const [animatedText, setAnimatedText] = useState([]);

  useEffect(() => {
    const text = 'Thank you for Beeeing Patient!';
    const delay = 200;

    // Split text into words and calculate animation delays
    const textArray = text.split(' ').map((word, index) => ({
      word,
      delay: index * 200 + delay,
    }));

    setAnimatedText(textArray);
  }, []);

  return (
    <div className="overlaySpinner" id="overlayscroll">
      <img alt="loading..." src={Bee} style={{ height: '100vh' }} />
      <div id="animated" style={{ color: 'white', position: 'absolute' }}>
        {animatedText.map(({ word, delay }, index) => (
          <span
            key={index}
            className="wavy-word"
            style={{ animationDelay: `${delay}ms`, marginRight: '8px' }}
          >
            {word}
          </span>
        ))}
      </div>
    </div>
  );
};
