import axiosRetry from 'axios-retry';

export const DEFAULT_RETRY_STRATEGY = {
  retries: 3, // Number of retry attempts
  retryDelay: retryCount => {
    console.log('Retrying request - Attempt:', retryCount);
    //exponential delay with a base delay (e.g., 1000ms)
    return retryCount * 2 * 1000;
  },
  retryCondition: error => {
    // Retry only for specific status codes
    return [500, 501, 503, 504].includes(error.response?.status);
  },
};

export const MEME_ENDPOINT_URL = 'http://postalpincode.in/api/pincode/';

//1- > Changing sub reddits   2-> add Scrollable Memes (10,20,50)

export const kanpurPinCodes = [
  '209861',
  '208001',
  '208002',
  '208003',
  '208004',
  '208005',
  '208006',
  '208007',
  '208008',
  '208009',
  '208010',
  '208011',
  '208012',
  '208013',
  '208014',
  '208015',
  '208016',
  '208017',
  '208018',
  '208019',
  '208020',
  '208021',
  '208022',
  '208023',
  '208024',
  '208025',
  '208026',
  '208027',
];
