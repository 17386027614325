import React from 'react';
import styles from '../../Styles/Secondarybutton.module.scss';

export const SecondaryButton = ({ text, isDisabled = false, onClick }) => {
  return (
    <button
      onClick={onClick}
      disabled={isDisabled}
      className={styles.buttonWrapper}
    >
      {text}
    </button>
  );
};
