import vermicompostImg from '../Assets/Products/Vermicompost.jpg';
import neemcakeImg from '../Assets/Products/Neem Cake.jpg';
import riceHuskImg from '../Assets/Products/Rice husk.jpg';
import carbonizedRiceHuskImg from '../Assets/Products/Carbonized Rice Husk.jpg';
import cowManureImg from '../Assets/Products/Cow Manure.jpg';
import perliteImg from '../Assets/Products/Perlite.jpg';
import vermiculiteImg from '../Assets/Products/Vermiculite.jpg';
import boneMealImg from '../Assets/Products/Bone Meal.jpg';
import bonsaiMixImg from '../Assets/Products/Bonsai Mix.jpg';
import riverSandImg from '../Assets/Products/River Sand.jpg';
import cinderImg from '../Assets/Products/Cinder.jpg';
import fineRiverSandImg from '../Assets/Products/Fine River Sand.jpg';
import mossGrass from '../Assets/Products/Moss Grass.jpg';
import coarseRiverSand from '../Assets/Products/Coarse River Sand.jpg';
import charcoal from '../Assets/Products/Charcoal.jpg';
import soilMix from '../Assets/Products/Soil Mix.jpg';
import alluvialSoil from '../Assets/Products/Alluvial Soil.jpg';

export const Items = [
  {
    category: ['Fertilizers'],
    itemName: 'Vermicompost',
    ratingValue: 4.5,
    itemDescription: [
      'Our Vermicompost is a high-quality organic compost made from earthworms, ideal for enriching garden soil and promoting vigorous plant growth.',
      'It helps improve soil structure, water retention, and nutrient availability, ensuring your plants receive the essential nutrients they need.',
      'Perfect for all types of plants, including vegetables, flowers, and shrubs, Vermicompost boosts plant health and enhances overall garden productivity.',
      'Our compost is carefully processed to maintain its natural properties, providing a sustainable and eco-friendly solution for your gardening needs.',
      'Available in a convenient 1 kg package, our Vermicompost is easy to handle and store, making it a great addition to any gardening routine.',
    ],
    itemImages: [
      vermicompostImg,
      '/images/Vermicompost/Vermicompost_1.jpg',
      '/images/Vermicompost/Vermicompost_2.jpg',
      '/images/Vermicompost/Vermicompost_3.jpg',
      '/images/Vermicompost/Vermicompost_4.jpg',
    ],
    outOfStock: false,
    Id: 1,

    itemImage: vermicompostImg,
    quantity: 0,
    itemLongName:
      'Gardening Bee Vermicompost Manure, Organic Fertilizer for Plants, Natural  plant booster',
    variation: [
      { price: 449, size: '1 Kilogram', discountPrice: 199 },
      { price: 599, size: '2 Kilogram', discountPrice: 299 },
      { price: 799, size: '5 Kilogram', discountPrice: 379 },
    ],
  },
  {
    ratingValue: 4,
    category: ['Soil Additives'],
    itemName: 'Alluvial Soil',
    itemDescription: [
      'Gardening Bee Alluvial Soil is a high-quality, nutrient-rich soil sourced from the Gangetic plains, perfect for enhancing garden beds and containers.',
      'Rich in organic matter and essential minerals, this soil mix ensures superior drainage, ideal for a wide variety of plants.',
      'Our Alluvial Soil improves plant root development, enhancing overall growth and ensuring your garden flourishes throughout the year.',
      'Free from harmful chemicals, this soil is a sustainable and eco-friendly option for healthy gardening.',
      'Available in convenient packaging, Gardening Bee Alluvial Soil is perfect for both novice and experienced gardeners seeking a high-performance soil solution.',
    ],
    itemImages: [
      alluvialSoil,
      '/images/Alluvial Soil/Alluvial_Soil_1.jpg',
      '/images/Alluvial Soil/Alluvial_Soil_2.jpg',
      '/images/Alluvial Soil/Alluvial_Soil_3.jpg',
      '/images/Alluvial Soil/Alluvial_Soil_4.jpg',
    ],
    outOfStock: false,
    Id: 2,

    itemImage: alluvialSoil,
    quantity: 0,
    itemLongName:
      'Gardening Bee Alluvial Soil for Plants, Just Soil, Enhances Drainage, Organic Fertilizer, Natural, Mitti',
    variation: [
      { price: 449, size: '1 Kilogram', discountPrice: 199 },
      { price: 599, size: '2 Kilogram', discountPrice: 299 },
      { price: 799, size: '5 Kilogram', discountPrice: 379 },
    ],
  },
  {
    ratingValue: 5,
    category: ['Soil Additives'],
    itemName: 'Soil Mix',
    itemDescription: [
      'Gardening Bee Soil Mix is a premium blend designed for healthy, thriving plants. It combines Vermicompost, Cocopeat, good bacteria, and soil for optimum growth.',
      'This soil mix enhances nutrient absorption, promotes strong root development, and improves soil texture for better water retention.',
      'Perfect for a wide range of plants, including vegetables, flowers, and houseplants, our mix ensures superior growth and vibrant plant health.',
      'Eco-friendly and sustainable, Gardening Bee Soil Mix is free from harmful chemicals, providing a safe environment for your plants.',
      'Easy to handle and store, this high-quality Soil Mix is a must-have for gardeners seeking top-notch results.',
    ],
    itemImages: [
      soilMix,
      '/images/Soil Mix/Soil_Mix_1.jpg',
      '/images/Soil Mix/Soil_Mix_2.jpg',
      '/images/Soil Mix/Soil_Mix_3.jpg',
      '/images/Soil Mix/Soil_Mix_4.jpg',
    ],
    outOfStock: false,
    Id: 3,

    itemImage: soilMix,
    quantity: 0,
    itemLongName:
      'Gardening Bee Soil Mix for Gardening: Premium Enriched Organic Potting, Compost & Manure Fertilizer Blend',
    variation: [
      { price: 449, size: '1 Kilogram', discountPrice: 199 },
      { price: 599, size: '2 Kilogram', discountPrice: 299 },
      { price: 799, size: '5 Kilogram', discountPrice: 379 },
    ],
  },
  {
    ratingValue: 3.5,
    category: ['Soil Additives'],
    itemName: 'Charcoal',
    itemDescription: [
      'Gardening Bee Charcoal is a natural soil amendment that helps improve soil aeration, root development, and overall plant health.',
      'Ideal for use in garden beds, potted plants, and bonsais, Charcoal helps maintain moisture balance and prevent root rot.',
      'This charcoal is free from harmful chemicals and is a sustainable, eco-friendly solution for improving soil structure.',
      'Gardening Bee Charcoal is also effective in absorbing excess moisture and odors, creating a healthier environment for your plants.',
      'Available in convenient packaging, our premium Charcoal is an essential addition to your plant care routine, ensuring strong roots and vibrant growth.',
    ],

    itemImages: [
      charcoal,
      '/images/Charcoal/Charcoal_1.jpg',
      '/images/Charcoal/Charcoal_2.jpg',
      '/images/Charcoal/Charcoal_3.jpg',
      '/images/Charcoal/Charcoal_4.jpg',
    ],
    outOfStock: false,
    Id: 4,

    itemImage: charcoal,
    quantity: 0,
    itemLongName:
      'Gardening Bee Wood Based Charcoal, Kitchen, Barbeque, Orchids and Gardening, Koyla, Coal, Horticulture, Plants',
    variation: [
      { price: 449, size: '500 Grams', discountPrice: 199 },
      { price: 599, size: '1 Kilogram', discountPrice: 299 },
      { price: 799, size: '2 Kilogram', discountPrice: 379 },
    ],
  },
  {
    ratingValue: 4,
    category: ['Soil Additives'],
    itemName: 'Coarse Sand',
    itemDescription: [
      'Gardening Bee Coarse River Sand is an excellent soil amendment for Adeniums, succulents, and other drought-tolerant plants that need well-drained soil.',
      'Our river sand ensures improved soil structure and drainage, preventing waterlogging and helping prevent root rot in sensitive plants.',
      'Ideal for creating the perfect soil mix for succulents, cacti, and ornamental plants, it encourages healthy growth and vibrant blooms.',
      'Naturally sourced and chemical-free, Gardening Bee Coarse River Sand is an eco-friendly addition to your gardening toolkit.',
      'Available in easy-to-use packages, this premium river sand is the perfect choice for any gardener looking to improve soil drainage and plant health.',
    ],
    itemImages: [
      coarseRiverSand,
      '/images/River Sand/River_Sand_1.jpg',
      '/images/River Sand/River_Sand_2.jpg',
      '/images/River Sand/River_Sand_3.jpg',
      '/images/River Sand/River_Sand_4.jpg',
    ],
    outOfStock: false,
    Id: 5,

    itemImage: coarseRiverSand,
    quantity: 0,
    itemLongName:
      'Gardening Bee Coarse River Sand for Plants, 2-4mm Particles, for Cactus, Succulents and Adenium',
    variation: [
      { price: 449, size: '1 Kilogram', discountPrice: 199 },
      { price: 599, size: '2 Kilogram', discountPrice: 299 },
      { price: 799, size: '5 Kilogram', discountPrice: 379 },
    ],
  },
  {
    ratingValue: 4,
    category: ['Soil Additives'],
    itemName: 'Moss Grass',
    itemDescription: [
      'Gardening Bee Moss Grass is a premium, natural product perfect for enhancing soil aeration and improving moisture retention in your garden.',
      'Ideal for adding a layer of ground cover, Moss Grass helps prevent soil erosion while providing a vibrant, lush green appearance.',
      'It’s a versatile option for lawns, garden beds, and terrariums, promoting healthier plant roots and reducing water runoff.',
      'Sustainably harvested, our Moss Grass is free of chemicals, making it an eco-friendly choice for both beginners and experienced gardeners.',
      'Available in various quantities, Gardening Bee Moss Grass is easy to incorporate into your gardening routine for a beautiful, thriving garden.',
    ],
    itemImages: [
      mossGrass,
      '/images/Moss Grass/Moss_Grass_1.jpg',
      '/images/Moss Grass/Moss_Grass_2.jpg',
      '/images/Moss Grass/Moss_Grass_3.jpg',
      '/images/Moss Grass/Moss_Grass_4.jpg',
    ],
    outOfStock: false,
    Id: 6,
    itemImage: mossGrass,
    quantity: 0,
    itemLongName:
      'Gardening Bee Dried Moss for Potted Plants: Natural Sphagnum for Indoor Decor, Crafts, Garden Mulching, & Soil Mix',
    variation: [
      { price: 449, size: '250 Grams', discountPrice: 249 },
      { price: 599, size: '500 Grams', discountPrice: 399 },
      { price: 799, size: '1 Kilogram', discountPrice: 479 },
      { price: 999, size: '2 Kilogram', discountPrice: 579 },
    ],
  },

  {
    ratingValue: 3.5,
    category: ['Fertilizers'],
    itemName: 'Neemcake',
    itemDescription: [
      'Our Neem Cake is available in an easy-to-use granular form, perfect for mixing into soil for a healthier garden.',
      'Handmade and free from artificial additives, our Neem Cake offers a 100% natural solution for your gardening needs.',
      'Neem Cake is renowned for its ability to combat fungus and pests, providing essential protection for your plants.',
      'Enrich your soil with Neem Cake. It enhances soil quality, promotes healthy plant growth, and improves overall garden productivity.',
      'Our Neem Cake comes in a convenient 1 kg package, ensuring you have the right amount for your gardening needs.',
      'Not just a fertilizer, Neem Cake also helps improve soil texture and fertility over time, making it a valuable addition to your gardening routine.',
      'With its natural properties, Neem Cake contributes to sustainable gardening practices by reducing the need for chemical inputs and promoting a balanced ecosystem.',
    ],
    itemImages: [
      neemcakeImg,
      '/images/Neem Cake/Neem_Cake_1.jpg',
      '/images/Neem Cake/Neem_Cake_2.jpg',
      '/images/Neem Cake/Neem_Cake_3.jpg',
      '/images/Neem Cake/Neem_Cake_4.jpg',
    ],
    outOfStock: false,
    Id: 7,
    itemImage: neemcakeImg,
    quantity: 0,
    itemLongName:
      'Gardening Bee Neem Khali/Cake for Plants, Natural, 100% Organic and Pest Repellent Fertilizer and Manure',
    variation: [
      { price: 449, size: '1 Kilogram', discountPrice: 249 },
      { price: 599, size: '2 Kilogram', discountPrice: 399 },
      { price: 799, size: '5 Kilogram', discountPrice: 599 },
    ],
  },
  {
    ratingValue: 3.5,
    category: ['Soil Additives'],
    itemName: 'Rice Husk',
    itemDescription: [
      'Rice Husk is an excellent soil additive that improves soil aeration, drainage, and moisture retention for healthier plant roots.',
      'Organic and eco-friendly, it helps enhance soil structure and promotes better root development and plant growth.',
      'Perfect for use in garden beds, pots, and containers, Rice Husk is versatile and easy to incorporate into various soil types.',
      'Our Rice Husk is processed to maintain its natural benefits, making it a valuable addition to any gardening or horticultural practice.',
      'Available in a convenient 1 kg package, it provides a practical solution for optimizing soil health and boosting plant vitality.',
    ],
    itemImages: [
      riceHuskImg,
      '/images/Rice Husk/Rice_Husk_1.jpg',
      '/images/Rice Husk/Rice_Husk_2.jpg',
      '/images/Rice Husk/Rice_Husk_3.jpg',
      '/images/Rice Husk/Rice_Husk_4.jpg',
    ],
    outOfStock: false,
    Id: 8,
    itemImage: riceHuskImg,
    quantity: 0,
    itemLongName:
      'Gardening Bee Organic Paddy Rice Hulls (Husk) For Plants, 100% Pure, Soil Potting Mixture, Natural Soil Manure',
    variation: [
      { price: 599, size: '500 Grams', discountPrice: 199 },
      { price: 399, size: '1 Kilogram', discountPrice: 199 },
      { price: 499, size: '2 Kilogram', discountPrice: 299 },
      { price: 699, size: '3 Kilogram', discountPrice: 399 },
      { price: 799, size: '4 Kilogram', discountPrice: 499 },
      { price: 999, size: '5 Kilogram', discountPrice: 549 },
    ],
  },
  {
    ratingValue: 4.5,
    category: ['Soil Additives'],
    itemName: 'Carbonized Rice Husk',
    itemDescription: [
      'Carbonized Rice Husk is a premium soil additive that enhances soil structure, adds essential nutrients, and improves overall soil fertility.',
      'Ideal for use in both garden beds and potted plants, it helps increase soil porosity and promotes better root growth.',
      'Our carbonized rice husk is carefully processed to maximize its benefits, making it an effective and sustainable choice for improving soil health.',
      'Perfect for organic gardening, it supports sustainable practices by reducing waste and enhancing soil quality naturally.',
      'Available in a 250 grams package, it provides a convenient and practical solution for boosting soil health in various gardening applications.',
    ],
    itemImages: [
      carbonizedRiceHuskImg,
      '/images/Carbonized Rice Husk/Carbonized_Rice_Husk_1.jpg',
      '/images/Carbonized Rice Husk/Carbonized_Rice_Husk_2.jpg',
      '/images/Carbonized Rice Husk/Carbonized_Rice_Husk_3.jpg',
      '/images/Carbonized Rice Husk/Carbonized_Rice_Husk_4.jpg',
    ],
    outOfStock: false,
    Id: 9,
    itemImage: carbonizedRiceHuskImg,
    quantity: 0,
    itemLongName:
      'Gardening Bee Carbonized Rice Husk: 95% Burnt Natural Soil Potting Mix with Organic Manure & Cinder Alternative',
    variation: [
      { price: 399, size: '500 Grams', discountPrice: 199 },
      { price: 549, size: '1 Kilogram', discountPrice: 299 },
      { price: 649, size: '2 Kilogram', discountPrice: 399 },
    ],
  },
  {
    ratingValue: 4,
    category: ['Fertilizers'],
    itemName: 'Cow Manure',
    itemDescription: [
      'Cow Manure is a natural and effective organic fertilizer that enhances soil fertility and promotes healthy plant growth.',
      'Rich in essential nutrients, it helps improve soil structure and increases water and nutrient retention for better plant development.',
      'Ideal for use in vegetable gardens, flower beds, and lawns, Cow Manure supports robust plant growth and high crop yields.',
      'Our Cow Manure is carefully processed to ensure its quality and effectiveness, providing a reliable solution for all your gardening needs.',
      'Available in a convenient 1 kg package, it is easy to apply and integrates well with various soil types.',
    ],
    itemImages: [
      cowManureImg,
      '/images/Cow Manure/Cow_Manure_1.jpg',
      '/images/Cow Manure/Cow_Manure_2.jpg',
      '/images/Cow Manure/Cow_Manure_3.jpg',
      '/images/Cow Manure/Cow_Manure_4.jpg',
    ],
    outOfStock: false,
    Id: 10,
    itemImage: cowManureImg,
    quantity: 0,
    itemLongName:
      'Gardening Bee Cow Dung Manure (Gobar Khad) for Plants & Home, Natural Fertilizer, 100% Organic Compost',
    variation: [
      { price: 419, size: '1 Kilogram', discountPrice: 199 },
      { price: 599, size: '2 Kilogram', discountPrice: 299 },
      { price: 799, size: '5 Kilogram', discountPrice: 379 },
    ],
  },
  {
    ratingValue: 4,
    category: ['Soil Additives'],
    itemName: 'Perlite',
    itemDescription: [
      'Perlite is a lightweight and effective soil additive that increases soil aeration and improves drainage for better root health.',
      'Ideal for use in potting mixes and garden beds, it helps prevent soil compaction and promotes efficient water and nutrient uptake.',
      'Our Perlite is processed to maintain its natural properties, providing a reliable solution for enhancing soil structure and plant growth.',
      'Perfect for both indoor and outdoor gardening, Perlite supports healthy root development and overall plant vitality.',
      'Available in a convenient 250 grams package, it is easy to use and integrates seamlessly into various soil blends.',
    ],
    itemImages: [
      perliteImg,
      '/images/Perlite/Perlite_1.jpg',
      '/images/Perlite/Perlite_2.jpg',
      '/images/Perlite/Perlite_3.jpg',
      '/images/Perlite/Perlite_4.jpg',
    ],
    outOfStock: false,
    Id: 11,
    itemImage: perliteImg,
    quantity: 0,
    itemLongName:
      'Gardening Bee Perlite for plants, Soil mix for Succulents, Bonsai, Adenium, Horticultural Grade Potting Mixture',
    variation: [
      { price: 399, size: '250 Grams', discountPrice: 199 },
      { price: 549, size: '500 Grams', discountPrice: 299 },
      { price: 549, size: '1 Kilogram', discountPrice: 349 },
    ],
  },
  {
    ratingValue: 4,
    category: ['Soil Additives'],
    itemName: 'Vermiculite',
    itemDescription: [
      'Vermiculite is an excellent soil additive that improves soil moisture retention, aeration, and overall plant health.',
      'Ideal for seed starting and potting mixes, it helps create a well-draining and nutrient-rich environment for plants.',
      'Our Vermiculite is processed to preserve its natural benefits, providing a sustainable and effective solution for enhancing soil quality.',
      'Perfect for use in both indoor and outdoor gardening, it supports healthy root growth and contributes to robust plant development.',
      'Available in a convenient 250 grams package, it is easy to handle and incorporate into various soil blends.',
    ],
    itemImages: [
      vermiculiteImg,
      '/images/Vermiculite/Vermiculite_1.jpg',
      '/images/Vermiculite/Vermiculite_2.jpg',
      '/images/Vermiculite/Vermiculite_3.jpg',
      '/images/Vermiculite/Vermiculite_4.jpg',
    ],
    outOfStock: false,
    Id: 12,
    itemImage: vermiculiteImg,
    quantity: 0,
    itemLongName:
      'Gardening Bee Vermiculite For Plant Growth, Natural Soil Conditioner, Hydroponics, Manure Fertilizer',
    variation: [
      { price: 399, size: '250 Grams', discountPrice: 199 },
      { price: 549, size: '500 Grams', discountPrice: 299 },
      { price: 549, size: '1 Kilogram', discountPrice: 349 },
    ],
  },
  {
    ratingValue: 4,
    category: ['Fertilizers'],
    itemName: 'Bone Meal',
    itemDescription: [
      'Bone Meal is a rich source of phosphorus that enhances root development and promotes strong, healthy plant growth.',
      'Ideal for use in vegetable gardens, flower beds, and lawns, it helps improve soil fertility and encourages robust root systems.',
      'Our Bone Meal is finely ground and carefully processed to maximize its effectiveness, providing a valuable addition to any gardening routine.',
      'Perfect for both new plantings and established gardens, it supports sustainable gardening practices by enriching the soil naturally.',
      'Available in a convenient 1 kg package, it is easy to apply and integrates well with various soil types.',
    ],
    itemImages: [
      boneMealImg,
      '/images/Bone Meal/Bone_Meal_1.jpg',
      '/images/Bone Meal/Bone_Meal_2.jpg',
      '/images/Bone Meal/Bone_Meal_3.jpg',
      '/images/Bone Meal/Bone_Meal_4.jpg',
    ],
    outOfStock: false,
    Id: 13,
    itemImage: boneMealImg,
    quantity: 0,
    itemLongName:
      'Gardening Bee Bonemeal Fertilizer Manure for Plants | Potting Mixture | Vegetable Flowering',
    variation: [
      { price: 449, size: '1 Kilogram', discountPrice: 249 },
      { price: 599, size: '2 Kilogram', discountPrice: 399 },
      { price: 799, size: '5 Kilogram', discountPrice: 599 },
    ],
  },
  {
    ratingValue: 4.5,
    category: ['Soil Additives'],
    itemName: 'Bonsai Mix',
    itemDescription: [
      'Bonsai Mix is specially formulated to support the unique needs of bonsai trees, enhancing soil fertility and plant health.',
      'It provides the perfect balance of nutrients and soil structure to promote healthy growth and vibrant foliage in bonsai plants.',
      'Our mix is designed to improve soil aeration, drainage, and moisture retention, ensuring optimal conditions for bonsai development.',
      'Ideal for both novice and experienced bonsai enthusiasts, it supports sustainable gardening practices by using natural and high-quality ingredients.',
      'Available in a convenient 1 kg package, it is easy to use and integrates seamlessly into your bonsai care routine.',
    ],
    itemImages: [
      bonsaiMixImg,
      '/images/Bonsai Mix/Bonsai_Mix_1.jpg',
      '/images/Bonsai Mix/Bonsai_Mix_2.jpg',
      '/images/Bonsai Mix/Bonsai_Mix_3.jpg',
      '/images/Bonsai Mix/Bonsai_Mix_4.jpg',
    ],
    outOfStock: false,
    Id: 14,
    itemImage: bonsaiMixImg,
    quantity: 0,
    itemLongName:
      'Gardening Bee Organic Bonsai Mix, 100% Natural Potting Soil Mix, Plant Growth Manure, Ready to Use',
    variation: [
      { price: 429, size: '1 Kilogram', discountPrice: 229 },
      { price: 579, size: '2 Kilogram', discountPrice: 379 },
      { price: 979, size: '5 Kilogram', discountPrice: 579 },
    ],
  },
  {
    ratingValue: 4.5,
    category: ['Soil Additives'],
    itemName: 'River Sand',
    itemDescription: [
      'River Sand is an excellent choice for improving soil drainage and aeration, making it ideal for use in gardening and landscaping.',
      'Perfect for succulents and cacti, it helps create a well-draining environment that prevents waterlogging and root rot.',
      'Our River Sand is washed and screened to remove impurities, ensuring high-quality and consistent performance for various gardening applications.',
      'Ideal for use in garden beds, potted plants, and soil mixes, it supports healthy plant growth and enhances overall soil quality.',
      'Available in a convenient 1 kg package, it provides a practical and effective solution for optimizing soil conditions and promoting robust plant development.',
    ],
    itemImages: [
      riverSandImg,
      '/images/River Sand/River_Sand_1.jpg',
      '/images/River Sand/River_Sand_2.jpg',
      '/images/River Sand/River_Sand_3.jpg',
      '/images/River Sand/River_Sand_4.jpg',
    ],
    outOfStock: false,
    Id: 15,
    itemImage: riverSandImg,
    quantity: 0,
    itemLongName:
      'Gardening Bee River Sand for Plants, Organic, Lawn and Aquarium, 100% Pure Sand Planted Substrate',
    variation: [
      { price: 419, size: '1 Kilogram', discountPrice: 199 },
      { price: 579, size: '2 Kilogram', discountPrice: 279 },
      { price: 779, size: '5 Kilogram', discountPrice: 379 },
    ],
  },
  {
    ratingValue: 5,
    category: ['Soil Additives'],
    itemName: 'Cinder',
    itemDescription: [
      'Cinder is an excellent soil additive that enhances drainage and aeration, making it ideal for various gardening needs.',
      'This lightweight volcanic rock helps to improve soil structure, promoting healthy root development and reducing waterlogging.',
      'Cinder is perfect for use in potting mixes, succulent gardens, and as a top dressing for bonsai and other container plants.',
      'Our cinder is naturally sourced and environmentally friendly, supporting sustainable gardening practices.',
      'Available in a convenient 1 kg package, it is easy to mix with other soil components to create the perfect growing environment.',
    ],
    itemImages: [
      cinderImg,
      '/images/Cinder/Cinder_1.jpg',
      '/images/Cinder/Cinder_2.jpg',
      '/images/Cinder/Cinder_3.jpg',
      '/images/Cinder/Cinder_4.jpg',
    ],
    outOfStock: false,
    Id: 16,
    itemImage: cinderImg,
    quantity: 0,
    itemLongName:
      'Gardening Bee Cinder (Washed) Natural Pure (2-8mm) for Succulents, Bonsai, Roses, Adeniums and Cactus',
    variation: [
      { price: 299, size: '1 Kilogram', discountPrice: 249 },
      { price: 449, size: '2 Kilogram', discountPrice: 349 },
      { price: 749, size: '5 Kilogram', discountPrice: 499 },
    ],
  },
  {
    ratingValue: 4,
    category: ['Soil Additives'],
    itemName: 'Fine River Sand',
    itemDescription: [
      'Fine River Sand is perfect for top dressing, improving soil texture, and enhancing soil aeration for healthier plant growth.',
      'Ideal for use in garden beds, potted plants, and seed starting mixes, it helps create a well-draining and nutrient-rich environment.',
      'Our Fine River Sand is carefully processed to ensure its quality and effectiveness, providing a valuable addition to any gardening routine.',
      'Supports better root development and prevents soil compaction, making it an essential component for a successful gardening practice.',
      'Available in a convenient 1 kg package, it is easy to handle and integrates seamlessly into various soil mixes and applications.',
    ],
    itemImages: [
      fineRiverSandImg,
      '/images/Fine River Sand/Fine_River_Sand_1.jpg',
      '/images/Fine River Sand/Fine_River_Sand_2.jpg',
      '/images/Fine River Sand/Fine_River_Sand_3.jpg',
      '/images/Fine River Sand/Fine_River_Sand_4.jpg',
    ],
    outOfStock: false,
    Id: 17,
    itemImage: fineRiverSandImg,
    quantity: 0,
    itemLongName:
      'Gardenig Bee Fine Natural River Sand (Baalu), Organic  Ganga River Sand for Pooja, Plants, Garden and Soilmix',
    variation: [
      { price: 419, size: '1 Kilogram', discountPrice: 199 },
      { price: 579, size: '2 Kilogram', discountPrice: 299 },
      { price: 779, size: '5 Kilogram', discountPrice: 379 },
    ],
  },
];

export function getProductById(id) {
  return Items.find(item => item.Id === id);
}
