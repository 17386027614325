import React, { useState } from 'react';
import '../../Styles/CategoriesNavbar.css';
import Categories from '../../Assets/Categories.svg';
import { Modal } from 'react-bootstrap';
import { Items } from '../../Assets/Catalogue';
export const MobileNav = () => {
  //create a hook or a utility instead of copy pasting it everywhere /// TODO
  const filterByCategory = category => {
    return Items.filter(item => item.category.includes(category));
  };

  const fertilizers = filterByCategory('Fertilizers');
  const soilAdditives = filterByCategory('Soil Additives');

  const [openDropdown, setOpenDropdown] = useState(false);
  const handleClose = () => setOpenDropdown(false);
  return (
    <>
      <div className="navBarWrapperMobile">
        <Modal
          show={openDropdown}
          onHide={handleClose}
          style={{ display: 'flex', alignItems: 'center' }}
          centered
        >
          <div className="centered-div">
            {openDropdown && (
              <div className="dropdownBlock">
                <a href="#Fertilizers" className="menuItem">
                  <p>Fertilizers</p>
                  <div className="numberofProduct"> {fertilizers.length} </div>
                </a>
                <a href="#SoilAdditives" className="menuItem">
                  <p>Soil Additives</p>
                  <div className="numberofProduct">{soilAdditives.length}</div>
                </a>
              </div>
            )}
          </div>
        </Modal>
        <div
          className="mobileNavBar"
          onClick={() => setOpenDropdown(!openDropdown)}
        >
          <img
            alt="Categories"
            style={{ color: 'white' }}
            src={Categories}
          ></img>
        </div>
      </div>
    </>
  );
};
