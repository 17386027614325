import { useSelector, useDispatch } from 'react-redux';
import {
  setFirstName,
  setLastName,
  setEmailId,
  setMobileNumber,
  setAddress,
  setLocality,
  setLandmark,
  setCity,
  setState,
  setPinCode,
  setModalHidden,
} from '../slice/user';

export const useUser = () => {
  const dispatch = useDispatch();

  const stateCodeMap = {
    Delhi: 'DL',
    Chandigarh: 'CH',
    Haryana: 'HR',
    'Himachal Pradesh': 'HP',
    'Jammu and Kashmir': 'JK',
    Punjab: 'PB',
    'Uttar Pradesh': 'UP',
    Uttarakhand: 'UT',
    'Dadra and Nagar Haveli': 'DN',
    'Daman and Diu': 'DD',
    Gujarat: 'GA',
    Rajasthan: 'RJ',
    Chhattisgarh: 'CT',
    Goa: 'GA',
    'Madhya Pradesh': 'MP',
    Maharashtra: 'MH',
    'Andhra Pradesh': 'AP',
    Karnataka: 'KA',
    Telangana: 'TG',
    Kerala: 'KL',
    Lakshadweep: 'LD',
    Puducherry: 'PY',
    'Tamil Nadu': 'TN',
    'Andaman and Nicobar Islands': 'AN',
    'Arunachal Pradesh': 'AR',
    Assam: 'AS',
    Manipur: 'MN',
    Meghalaya: 'ML',
    Mizoram: 'MZ',
    Nagaland: 'NL',
    Odisha: 'OR',
    Sikkim: 'SK',
    Tripura: 'TR',
    'West Bengal': 'WB',
    Bihar: 'BR',
    Jharkhand: 'JH',
    'Field Post Office': 'FPO',
    'Army Post Office': 'APO',
  };

  const getStateFromPinCode = pinCode => {
    if (!/^\d{6}$/.test(pinCode) || pinCode.startsWith('0')) {
      return 'Invalid PIN Code';
    }

    const firstDigit = pinCode.charAt(0);

    switch (firstDigit) {
      case '1':
        // Based on ranges: Delhi (110xxx), Chandigarh (160xxx), etc.
        if (pinCode.startsWith('1')) {
          if (pinCode.startsWith('11')) return 'Delhi';
          if (pinCode.startsWith('12')) return 'Haryana';
          if (pinCode.startsWith('14')) return 'Himachal Pradesh';
          if (pinCode.startsWith('18')) return 'Jammu and Kashmir';
          if (pinCode.startsWith('19')) return 'Punjab';
          if (pinCode.startsWith('16')) return 'Chandigarh';
        }
        break;
      case '2':
        // Based on ranges: Uttar Pradesh (20xxx to 29xxx), Uttarakhand (24xxx)
        if (pinCode.startsWith('20') || pinCode.startsWith('21'))
          return 'Uttar Pradesh';
        if (pinCode.startsWith('24')) return 'Uttarakhand';
        break;
      case '3':
        // Based on ranges: Dadra and Nagar Haveli (396xxx), Daman and Diu (396xxx), etc.
        if (pinCode.startsWith('30')) return 'Gujarat';
        if (pinCode.startsWith('32')) return 'Dadra and Nagar Haveli';
        if (pinCode.startsWith('36')) return 'Daman and Diu';
        if (pinCode.startsWith('31') || pinCode.startsWith('33'))
          return 'Rajasthan';
        break;
      case '4':
        // Based on ranges: Madhya Pradesh (45xxx to 49xxx), etc.
        if (pinCode.startsWith('45') || pinCode.startsWith('46'))
          return 'Madhya Pradesh';
        if (pinCode.startsWith('40') || pinCode.startsWith('41'))
          return 'Maharashtra';
        if (pinCode.startsWith('40')) return 'Goa';
        break;
      case '5':
        // Based on ranges: Andhra Pradesh (50xxx to 53xxx), Karnataka (56xxx to 59xxx), etc.
        if (pinCode.startsWith('50') || pinCode.startsWith('51'))
          return 'Andhra Pradesh';
        if (pinCode.startsWith('56')) return 'Karnataka';
        if (pinCode.startsWith('52') || pinCode.startsWith('53'))
          return 'Telangana';
        break;
      case '6':
        // Based on ranges: Kerala (67xxx to 69xxx), Lakshadweep (68xxx), Puducherry (60xxx), etc.
        if (pinCode.startsWith('60') || pinCode.startsWith('61'))
          return 'Puducherry';
        if (pinCode.startsWith('67') || pinCode.startsWith('68'))
          return 'Kerala';
        if (pinCode.startsWith('68')) return 'Lakshadweep';
        if (pinCode.startsWith('64')) return 'Tamil Nadu';
        break;
      case '7':
        // Based on ranges: West Bengal (70xxx to 74xxx), Assam (78xxx to 79xxx), etc.
        if (pinCode.startsWith('70') || pinCode.startsWith('71'))
          return 'West Bengal';
        if (pinCode.startsWith('77') || pinCode.startsWith('78'))
          return 'Assam';
        if (pinCode.startsWith('76')) return 'Sikkim';
        if (pinCode.startsWith('75')) return 'Odisha';
        break;
      case '8':
        // Based on ranges: Bihar (80xxx to 82xxx), Jharkhand (81xxx to 83xxx)
        if (pinCode.startsWith('80') || pinCode.startsWith('81'))
          return 'Bihar';
        if (pinCode.startsWith('82') || pinCode.startsWith('83'))
          return 'Jharkhand';
        break;
      case '9':
        // Based on ranges: Field Post Office (90xxx), Army Post Office (91xxx)
        if (pinCode.startsWith('90')) return 'Field Post Office';
        if (pinCode.startsWith('91')) return 'Army Post Office';
        break;
      default:
        return 'Invalid PIN Code';
    }
  };

  // Function to update state based on PIN code
  const updateStateFromPinCode = pinCode => {
    // const state = getStateFromPinCode(pinCode);
    const stateName = getStateFromPinCode(pinCode);
    const stateCode = stateCodeMap[stateName] || 'Invalid'; // Get the state code
    dispatch(setState(stateCode));
    // dispatch(setState(state));
  };

  // Access the user state from the Redux store
  const user = useSelector(state => state.user);

  // Functions to update various fields
  const hideModal = () => {
    dispatch(setModalHidden());
  };
  const updateFirstName = firstName => dispatch(setFirstName(firstName));
  const updateLastName = lastName => dispatch(setLastName(lastName));
  const updateEmailId = emailId => dispatch(setEmailId(emailId));
  const updateMobileNumber = mobileNumber =>
    dispatch(setMobileNumber(mobileNumber));
  const updateAddress = address => dispatch(setAddress(address));
  const updateLocality = locality => dispatch(setLocality(locality));
  const updateLandmark = landmark => dispatch(setLandmark(landmark));
  const updateCity = city => dispatch(setCity(city));
  const updateState = state => dispatch(setState(state));
  const updatePinCode = pinCode => {
    dispatch(setPinCode(pinCode));
    updateStateFromPinCode(pinCode);
  };

  return {
    user,
    updateFirstName,
    updateLastName,
    updateEmailId,
    updateMobileNumber,
    updateAddress,
    updateLocality,
    updateLandmark,
    updateCity,
    updateState,
    updatePinCode,
    hideModal,
  };
};
