import { useDispatch, useSelector } from 'react-redux';
import {
  addItem,
  clearCart,
  clearOrderId,
  decreaseCartItem,
  increaseCartItem,
  removeItem,
  setDiscount,
  setOrderId,
  removeDiscount,
} from '../slice/cart';

export const useCart = () => {
  const dispatch = useDispatch();

  const addInCart = item => {
    dispatch(addItem(item));
  };

  const addDiscount = cuponDetails => {
    dispatch(setDiscount(cuponDetails));
  };

  const removeFromCart = index => {
    dispatch(removeItem(index));
  };

  const getCart = useSelector(state => state?.cart?.items);
  // const couponDiscount = useSelector(state => state?.cart?.cuponDiscount);
  const orderId = useSelector(state => state?.cart?.orderId);
  const cuponDetails = useSelector(state => state?.cart?.coupon);

  const increaseItem = (id, size) => {
    dispatch(increaseCartItem({ id, size }));
  };

  const addOrderId = orderId => {
    dispatch(setOrderId(orderId));
  };

  const rmvDiscount = () => {
    dispatch(removeDiscount());
  };
  const decreaseItem = (id, size) => {
    if (
      getCart.some(
        cart => cart.Id === id && cart.size === size && cart.quantity === 1
      )
    ) {
      const index = getCart.findIndex(
        items => items.Id === id && items.size === size
      );
      dispatch(removeItem(index));
    } else {
      dispatch(decreaseCartItem({ id, size }));
    }
  };

  const clearCartItems = () => {
    dispatch(clearCart());
    dispatch(clearOrderId());
  };

  const clearUserOrderId = () => {
    dispatch(clearOrderId());
  };

  const getTotalCartValue = () => {
    let totalPrice = 0;
    let discountedPrice = 0;

    if (cuponDetails != null) {
      getCart.forEach(cartItem => {
        const selectedVariation = cartItem.variation.find(
          variation => variation.size === cartItem.size
        );
        if (selectedVariation) {
          totalPrice += selectedVariation.price * cartItem.quantity;
          discountedPrice +=
            selectedVariation.discountPrice * cartItem.quantity;
        } else {
          totalPrice += cartItem.itemPrice * cartItem.quantity;
          discountedPrice += cartItem.discountedPrice * cartItem.quantity;
        }
      });

      discountedPrice = discountedPrice * (1 - cuponDetails?.discount / 100);

      discountedPrice = Math.ceil(discountedPrice);

      return { totalPrice, discountedPrice };
    } else {
      getCart.forEach(cartItem => {
        const selectedVariation = cartItem.variation.find(
          variation => variation.size === cartItem.size
        );
        if (selectedVariation) {
          totalPrice += selectedVariation.price * cartItem.quantity;
          discountedPrice +=
            selectedVariation.discountPrice * cartItem.quantity;
        } else {
          totalPrice += cartItem.itemPrice * cartItem.quantity;
          discountedPrice += cartItem.discountedPrice * cartItem.quantity;
        }
      });

      return { totalPrice, discountedPrice };
    }
  };

  const getTotalValue = getTotalCartValue();

  return {
    addInCart,
    getCart,
    getTotalValue,
    removeFromCart,
    increaseItem,
    decreaseItem,
    addOrderId,
    orderId,
    clearCartItems,
    clearUserOrderId,
    addDiscount,
    rmvDiscount,
    cuponDetails,
  };
};
