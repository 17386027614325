import React from 'react';
import styles from './HamburgerMenu.module.scss';
import { Button, Drawer } from '@mui/material';
import { useNavigate } from 'react-router';

export const HamburgerMenu = () => {
  let navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = newOpen => () => {
    setOpen(newOpen);
  };

  return (
    <div>
      <Button onClick={toggleDrawer(true)}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="var(--primary-black)"
          xmlns="http://www.w3.org/2000/svg"
          alt="menu"
          className="MobileMenu_mobileMenuHamburger__27G0u"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.09961 11.9996C2.09961 11.5026 2.50255 11.0996 2.99961 11.0996H20.9996C21.4967 11.0996 21.8996 11.5026 21.8996 11.9996C21.8996 12.4967 21.4967 12.8996 20.9996 12.8996H2.99961C2.50255 12.8996 2.09961 12.4967 2.09961 11.9996Z"
          ></path>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.09961 5.99961C2.09961 5.50255 2.50255 5.09961 2.99961 5.09961H20.9996C21.4967 5.09961 21.8996 5.50255 21.8996 5.99961C21.8996 6.49667 21.4967 6.89961 20.9996 6.89961H2.99961C2.50255 6.89961 2.09961 6.49667 2.09961 5.99961Z"
          ></path>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.09961 17.9996C2.09961 17.5026 2.50255 17.0996 2.99961 17.0996H20.9996C21.4967 17.0996 21.8996 17.5026 21.8996 17.9996C21.8996 18.4967 21.4967 18.8996 20.9996 18.8996H2.99961C2.50255 18.8996 2.09961 18.4967 2.09961 17.9996Z"
          ></path>
        </svg>
      </Button>
      <Drawer
        PaperProps={{
          sx: { width: '100%' },
        }}
        style={{ width: '100%', height: '100vh' }}
        open={open}
        onClose={toggleDrawer(false)}
      >
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <h1>Menu</h1>
          <svg
            onClick={() => {
              setOpen(false);
            }}
            xmlns="http://www.w3.org/2000/svg"
            width="46"
            height="46"
            style={{ marginLeft: 'auto', cursor: 'pointer' }}
            fill="currentColor"
            className="bi bi-x"
            viewBox="0 0 16 16"
          >
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
          </svg>
        </div>
        <button
          className={styles.otherPagesButton}
          onClick={() => {
            {
              navigate('/privacy-policy');
            }
            {
              setOpen(false);
            }
          }}
        >
          <div className={styles.btnText}>Privacy Policy</div>
        </button>
        <button
          className={styles.otherPagesButton}
          onClick={() => {
            {
              navigate('/refund-policy');
            }
            {
              setOpen(false);
            }
          }}
        >
          <div className={styles.btnText}>Refund Policy</div>
        </button>
        <button
          className={styles.otherPagesButton}
          onClick={() => {
            {
              navigate('/terms-and-conditions');
            }
            {
              setOpen(false);
            }
          }}
        >
          <div className={styles.btnText}>Terms & Condition</div>
        </button>
      </Drawer>
    </div>
  );
};
