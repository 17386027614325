import React from 'react';
import styles from '../../Styles/PrimaryButton.module.scss';
import { Spinner } from 'react-bootstrap';

export const PrimaryButton = ({
  label,
  onClick,
  type,
  form,
  onSubmit,
  loading,
}) => {
  return (
    <button
      type={type}
      form={form}
      onClick={onClick}
      className={styles.buttonWrapper}
      onSubmit={onSubmit}
      disabled={loading}
    >
      {loading ? `Loading` : label}
      {loading ? (
        <Spinner
          style={{ marginLeft: '0.5rem' }}
          animation="grow"
          variant="light"
          size="sm"
        />
      ) : (
        <></>
      )}
    </button>
  );
};
