import { createSlice } from '@reduxjs/toolkit';

const cartSlice = createSlice({
  name: 'cart',
  initialState: { items: [], orderId: '', coupon: null },
  reducers: {
    addItem(state, action) {
      const { id, size } = action.payload;
      const existingItem = state.items.find(
        item => item.Id === id && item.size === size
      );

      if (existingItem) {
        existingItem.quantity += 1;
      } else {
        state.items.push({ ...action.payload, quantity: 1 });
      }
    },
    removeItem(state, action) {
      return {
        items: [
          ...state.items.slice(0, action.payload),
          ...state.items.slice(action.payload + 1),
        ],
      };
    },
    increaseCartItem(state, action) {
      const { id, size } = action.payload;
      const index = state.items.findIndex(
        item => item.Id === id && item.size === size
      );
      if (index !== -1) {
        state.items[index].quantity += 1;
      }
    },
    decreaseCartItem(state, action) {
      const { id, size } = action.payload;
      const index = state.items.findIndex(
        item => item.Id === id && item.size === size
      );
      if (index !== -1) {
        if (state.items[index].quantity > 1) {
          state.items[index].quantity -= 1;
        } else {
          state.items.splice(index, 1);
        }
      }
    },
    setOrderId(state, action) {
      state.orderId = action.payload;
    },
    setDiscount(state, action) {
      state.coupon = action.payload;
    },
    removeDiscount(state) {
      state.coupon = null;
    },
    clearCart(state) {
      state.items = [];
    },
    clearOrderId(state) {
      state.orderId = '';
    },
  },
});

export const {
  addItem,
  removeItem,
  increaseCartItem,
  decreaseCartItem,
  setOrderId,
  clearCart,
  clearOrderId,
  setDiscount,
  removeDiscount,
} = cartSlice.actions;
export const cartReducer = cartSlice.reducer;
