import React from 'react';
import styles from '../Styles/Cart.module.scss';
import emptyCart from '../Assets/EmptyCart.svg';
import { useNavigate } from 'react-router';
import { InvoiceContainer } from './InvoiceContainer/InvoiceContainer';
import { PaymentMethodFooter } from './PaymentMethodsFooter/PaymentMethodFooter';
import { useCart } from '../redux/hooks';
import { CuponCode } from './CuponCode/CuponCode';
import { PrimaryButton } from './Utility Components';

export const Cart = ({ setFlowState }) => {
  const {
    getCart,
    getTotalValue,
    removeFromCart,
    increaseItem,
    decreaseItem,
  } = useCart();

  const removeButtonClick = (index, event) => {
    event.stopPropagation();
    removeFromCart(index);
  };

  const navigate = useNavigate();

  //to be added in future (Location based discount / maybe)

  const handleProceedToAddress = () => {
    setFlowState(prev => ({ ...prev, cartCompleted: true }));
    navigate('/address');
  };

  const handleClick = (id, event) => {
    event.stopPropagation();
    navigate(`/product/${id}`);
  };

  const onDecreaseItem = (id, size, event) => {
    event.stopPropagation();
    decreaseItem(id, size);
  };

  const onIncreaseItem = (id, size, event) => {
    event.stopPropagation();
    increaseItem(id, size);
  };

  return (
    <>
      {getCart.length === 0 ? (
        <div className={styles.cartWrapper}>
          <img
            alt="Empty Cart"
            className={styles.emptyCartImg}
            src={emptyCart}
          ></img>
          <h3>Your Cart is empty</h3>
          <p className="mt-0">Looks like you haven't made your choice yet</p>
          <div className={styles.buttonWrapper}>
            <PrimaryButton
              label="Back to the Nursery?"
              onClick={() => {
                navigate('/');
              }}
            />
          </div>
        </div>
      ) : (
        <div className={styles.pageWrapper}>
          <div className={styles.mobileHeader}>
            <b>Shopping Bag</b>
            <div className={styles.stepInfo}>Step 1/3</div>
          </div>
          <div className={styles.checkoutWrapper}>
            <div className={styles.leftSection}>
              <div className={styles.billingInformation}>
                <div
                  className={styles.cartInfo}
                >{`Shopping Cart (${getCart.length} Items)`}</div>
                <div className={styles.priceInfo}>
                  Total ₹{getTotalValue.discountedPrice}
                </div>
              </div>
              {getCart.map((items, index) => {
                return (
                  <div key={index} className={styles.itemCardWrapper}>
                    <div
                      className={styles.itemCard}
                      onClick={event => {
                        handleClick(items.Id, event);
                      }}
                    >
                      <div className={styles.itemImgFrame}>
                        <img
                          alt={items.itemName}
                          src={items.itemImage}
                          className={styles.itemImg}
                        ></img>
                      </div>

                      <div className={styles.cardContent}>
                        <div className={styles.cardHeader}>
                          <div className={styles.itemName}>
                            {`${items.itemName
                              .replace(/Gardening Bee/g, '')
                              .trim()}
                             ${items.size}`}
                          </div>
                          <div
                            className={styles.removeBtn}
                            onClick={event => {
                              removeButtonClick(index, event);
                            }}
                          >
                            REMOVE
                          </div>
                        </div>
                        <div className={styles.cardBody}>
                          <div className={styles.itemPrice}>
                            ₹{items.discountPrice}
                            <div className={styles.strikedOut}>
                              {items.price}
                            </div>
                          </div>
                          <div className={styles.buttonsContainer}>
                            <button
                              className={styles.addsubsButton}
                              onClick={event => {
                                onDecreaseItem(items.Id, items.size, event);
                              }}
                              type="button"
                            >
                              -
                            </button>
                            {items.quantity}
                            <button
                              className={styles.addsubsButton}
                              onClick={event => {
                                onIncreaseItem(items.Id, items.size, event);
                              }}
                              type="button"
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={styles.mobileRemovebtn}
                      onClick={event => {
                        removeButtonClick(index, event);
                      }}
                    >
                      REMOVE
                    </div>
                  </div>
                );
              })}
            </div>

            <div className={styles.rightSection} id="pricedetails">
              <CuponCode />
              <InvoiceContainer
                onClick={() => {
                  handleProceedToAddress();
                }}
              />
            </div>
          </div>
          <div className={styles.mobileCheckout}>
            <div className={styles.mobileLeft}>
              <div className={styles.price}>
                ₹{getTotalValue.discountedPrice}
              </div>
              <a href="#pricedetails" className={styles.viewPriceDetails}>
                View price details
              </a>
            </div>
            <div className={styles.mobileRight}>
              <PrimaryButton
                label="Continue"
                onClick={() => {
                  handleProceedToAddress();
                }}
              ></PrimaryButton>
            </div>
          </div>
          <PaymentMethodFooter />
        </div>
      )}
    </>
  );
};
