import React from 'react';
import { useEffect } from 'react';
import Swal from 'sweetalert2';
import { useCart } from '../../redux/hooks';
import { useNavigate } from 'react-router';
import './Payment.css';

export const PaymentFailure = () => {


  let navigate = useNavigate();

  const onFailedPayment = () => {
    Swal.fire({
      title: 'Payment Failed',
      html: `
        <div class="swal-custom-content">
          <img src="https://i.postimg.cc/k4qJXCzX/Untitled-design-4.png" alt="Image" class="swal-custom-image">
          <div class="swal-custom-text">
            <p>Oops! Something went wrong. But don’t worry, your items are still safe in your cart! Ready to try again?</p>
          </div>
        </div>
      `,
      icon: 'error',
      confirmButtonText: 'Yes, complete my purchase!',
      showCloseButton: true,
    }).then(() => {
      navigate('/cart');
    });
  };

  useEffect(() => {
    onFailedPayment();
  }, []);

  return <div style={{ height: '80vh' }}></div>;
};
