import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({ title, description, keywords, image, url }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={url} />
    </Helmet>
  );
};

SEO.defaultProps = {
  title: 'Gardening Bee - Your One-Stop Shop for Garden Essentials',
  description: 'Gardening Bee offers a wide range of gardening products including fertilizers, vermicompost, pots, seedling pots, live plants, and rice husk. Shop online or on Amazon for all your gardening needs.',
  keywords: 'gardening bee, gardeningbee, gardening bee vermicompost, gardening bee products, gardening bee plants, gardening, fertilizers, vermicompost, pots, seedling pots, live plants, rice husk, gardening supplies, buy gardening products',
  image: 'https://i.postimg.cc/Kv8R2YZJ/Photoroom-20240709-123111.png',
  url: 'https://gardeningbee.com/',
};

export default SEO;
