import React, { useEffect, useState } from 'react';
import styles from '../../Styles/PaymentPage.module.scss';
import { InvoiceContainer } from '../InvoiceContainer/InvoiceContainer';
import { PaymentMethodFooter } from '../PaymentMethodsFooter/PaymentMethodFooter';
import card from '../../Assets/cardImg.svg';
import cod from '../../Assets/cash_ond.svg';
import axios from 'axios';
import { useCart, useUser } from '../../redux/hooks';
import { useNavigate } from 'react-router';
import { BackButton, PrimaryButton } from '../Utility Components';
import Swal from 'sweetalert2';
import happyBee from '../../Assets/happyBee.png';
import { BeeFlying } from './BeeFlying';

export const PaymentPage = ({ flowState }) => {
  const {
    getTotalValue,
    orderId,
    rmvDiscount,
    clearCartItems,
    clearUserOrderId,
  } = useCart();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const { user } = useUser();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
    'pay_online'
  );

  const onCompletePayment = () => {
    Swal.fire({
      title: 'Order Successful',
      html: `
        <div class="swal-custom-content">
          <img src=${happyBee} alt="Image" class="swal-custom-image-success">
          <div class="swal-custom-text">
            <p>Your order ID is ${orderId}. A confirmation will be sent to you shortly! Thank you for shopping with us!</p>
          </div>
        </div>
      `,
      icon: 'success',
      confirmButtonText: 'Back to the store!',
      showCloseButton: true,
    }).then(() => {
      clearCartItems();
      clearUserOrderId();
      navigate('/');
      return;
    });
  };

  const onFailedPayment = () => {
    Swal.fire({
      title: 'Payment Failed',
      html: `
        <div class="swal-custom-content">
          <img src="https://i.postimg.cc/k4qJXCzX/Untitled-design-4.png" alt="Image" class="swal-custom-image">
          <div class="swal-custom-text">
            <p>Oops! Something went wrong. But don’t worry, your items are still safe in your cart! Ready to try again?</p>
          </div>
        </div>
      `,
      icon: 'error',
      confirmButtonText: 'Yes, complete my purchase!',
      showCloseButton: true,
    }).then(() => {
      navigate('/cart');
      return;
    });
  };

  const onCancelledPayment = () => {
    Swal.fire({
      title: 'Payment Cancelled',
      html: `
        <div class="swal-custom-content">
          <img src="https://i.postimg.cc/k4qJXCzX/Untitled-design-4.png" alt="Cancelled" class="swal-custom-image">
          <div class="swal-custom-text">
            <p>Your payment was cancelled. No worries, you can always complete your purchase whenever you're ready!</p>
          </div>
        </div>
      `,
      icon: 'info',
      confirmButtonText: 'Return to Payment',
      showCloseButton: true,
    }).then(() => {
      return;
    });
  };

  useEffect(() => {
    if (!flowState.addressCompleted) {
      navigate('/address');
    }
  }, [flowState.addressCompleted, navigate]);

  const handlePaymentMethodSelect = method => {
    setSelectedPaymentMethod(method);
  };

  const openIframe = (url, id) => {
    const callback = async response => {
      if (response === 'USER_CANCEL') {
        /* Add merchant's logic if they have any custom thing to trigger on UI after the transaction is cancelled by the user*/
        onCancelledPayment();
        return;
      } else if (response === 'CONCLUDED') {
        setLoading(true);
        /* Add merchant's logic if they have any custom thing to trigger on UI after the transaction is in terminal state*/
        const formattedPayload = {
          orderId,
          email: user.address.emailId,
          name: user.address.firstName,
          transId: id,
        };
        try {
          const response = await axios.post(
            'https://gardening-bee-service.vercel.app/api/payment/validate',
            formattedPayload
          );

          if (response.data === 'success') {
            setLoading(false);
            onCompletePayment();
            return;
          } else {
            setLoading(false);
            onFailedPayment();
            return;
          }
        } catch (error) {
          console.error('Payment error: ', error);
          setLoading(false);
          return;
        }
      }
    };

    window.PhonePeCheckout.transact({
      tokenUrl: url,
      callback,
      type: 'IFRAME',
    });
  };

  const paymentClick = async () => {
    if (selectedPaymentMethod === 'pay_online') {
      setLoading(true);
      const formattedPayload = {
        amount: getTotalValue.discountedPrice,
        mobileNumber: user.address.mobileNumber,
        email: user.address.emailId,
        orderId: orderId,
        name: user.address.firstName,
        payment_method: selectedPaymentMethod,
      };

      try {
        const response = await axios.post(
          'https://gardening-bee-service.vercel.app/api/payment',
          formattedPayload
        );

        setLoading(false);
        rmvDiscount();
        openIframe(response.data.url, response.data.merchantTransactionId);
      } catch (error) {
        console.error('Payment error: ', error);
        setLoading(false);
      }
    } else {
      setLoading(true);
      const formattedPayload = {
        amount: getTotalValue.discountedPrice + 49,
        mobileNumber: user.address.mobileNumber,
        email: user.address.emailId,
        orderId: orderId,
        name: user.address.firstName,
        payment_method: selectedPaymentMethod,
      };

      try {
        const response = await axios.post(
          'https://gardening-bee-service.vercel.app/api/payment',
          formattedPayload
        );
        setLoading(false);
        rmvDiscount();
        navigate('/pay/success');
        if (response.data.includes('COD')) {
          onCompletePayment();
        } else {
          onFailedPayment();
        }
      } catch (error) {
        console.error('Payment error: ', error);
        setLoading(false);
        navigate('/pay/failure');
      }
    }
  };

  return (
    <div className={styles.paymentWrapper}>
      {loading && <BeeFlying />}
      <div className={styles.mobileHeader}>
        <div className="flex-row">
          <BackButton />
          <div className="font-bold f-16">Address</div>
        </div>
        <div className={styles.stepInfo}>Step 3/3</div>
      </div>
      <div className={styles.checkoutWrapper}>
        <div className={styles.leftSection}>
          <div className={styles.billingInformation}>
            <div className={styles.cartInfo}>Choose payment mode</div>
          </div>
          <div className={styles.paymentInfo}>
            <div className={`${styles.paymentOptions} ${styles.desktopHidden}`}>
              <div
                className={`${styles.paymentOption} ${
                  selectedPaymentMethod === 'pay_online'
                    ? styles.paymentActive
                    : ''
                }`}
                onClick={() => handlePaymentMethodSelect('pay_online')}
              >
                <img alt="pay online" src={card} />
                <div className="px-1 mx-1 font-bold">Online Payment</div>
              </div>
              <div
                className={`${styles.paymentOption} ${
                  selectedPaymentMethod === 'cod' ? styles.paymentActive : ''
                }`}
                onClick={() => handlePaymentMethodSelect('cod')}
              >
                <img alt="Cash on delivery" src={cod} />
                <div className="px-1 mx-1 font-bold">Cash on delivery</div>
              </div>
            </div>
            <div className={styles.paymentSpecifics}>
              <div
                onClick={() => handlePaymentMethodSelect('pay_online')}
                className={`${styles.paymentBox} ${
                  selectedPaymentMethod === 'cod' ? styles.desktopHidden : ''
                }`}
              >
                <div className={styles.desktopHidden}>
                  <img alt="pay online" src={card} />
                </div>

                <div className={styles.payCardContent}>
                  <div className={styles.paymentHeader}>Pay Online</div>
                  <div className={styles.paymentContent}>
                    Use credit/debit card, net-banking, UPI, wallets to complete
                    the payment.
                  </div>
                  <div className={styles.mobileHidden}>
                    <PrimaryButton
                      onClick={paymentClick}
                      loading={loading}
                      disable={loading}
                      label={`Pay ₹${getTotalValue.discountedPrice}`}
                    />
                  </div>
                </div>
                <div className={styles.desktopHiddenRadio}>
                  <input
                    type="radio"
                    name="paymentMethod"
                    value="pay_online"
                    checked={selectedPaymentMethod === 'pay_online'}
                    onClick={e => e.stopPropagation()}
                    onChange={() => handlePaymentMethodSelect('pay_online')}
                  />
                </div>
              </div>

              <div
                onClick={() => handlePaymentMethodSelect('cod')}
                className={`${styles.paymentBox} ${
                  selectedPaymentMethod === 'pay_online'
                    ? styles.desktopHidden
                    : ''
                }`}
              >
                <div className={styles.desktopHidden}>
                  <img alt="Cash on delivery" src={cod} />
                </div>

                <div className={styles.payCardContent}>
                  <div className={styles.paymentHeader}>Cash on delivery</div>
                  <b>*An extra charge of ₹49 will be applicable as COD fees</b>
                  <div className={styles.paymentContent}>
                    Pay in cash or pay in person at the time of delivery with
                    GPay/PayTM/PhonePe.
                  </div>
                  <div className={styles.mobileHidden}>
                    <PrimaryButton
                      onClick={paymentClick}
                      loading={loading}
                      disable={loading}
                      label="Place Order"
                    />
                  </div>
                </div>
                <div className={styles.desktopHiddenRadio}>
                  <input
                    type="radio"
                    name="paymentMethod"
                    checked={selectedPaymentMethod === 'cod'}
                    value="cod"
                    onChange={() => handlePaymentMethodSelect('cod')}
                    onClick={e => e.stopPropagation()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.rightSection} id="pricedetails">
          <InvoiceContainer
            buttonDisplay={true}
            cod={selectedPaymentMethod === 'cod' ? true : false}
          />
        </div>
      </div>
      <div className={styles.mobileCheckout}>
        <div className={styles.mobileLeft}>
          <div className={styles.price}>
            ₹
            {selectedPaymentMethod === 'cod'
              ? getTotalValue.discountedPrice + 49
              : getTotalValue.discountedPrice}
          </div>
          <a href="#pricedetails" className={styles.viewPriceDetails}>
            View price details
          </a>
        </div>
        <div className={styles.mobileRight}>
          <PrimaryButton
            loading={loading}
            disable={loading}
            onClick={paymentClick}
            label={` ${
              selectedPaymentMethod === 'pay_online'
                ? `Pay ₹${getTotalValue.discountedPrice}`
                : 'Place Order'
            }`}
          />
        </div>
      </div>
      <PaymentMethodFooter />
    </div>
  );
};
