import React from 'react';

export const RefundPage = () => {
  return (
    <div className="container">
      <h1 className="page-title">Refund Policy</h1>
      <p>&nbsp;</p>
      <p>Our Return and Refund Policy was last updated 08 July 2024</p>
      <p>Thank you for shopping at gardeningbee.in.</p>
      <p>
        If for any reason, you are not completely satisfied with a purchase, we
        invite you to review our policy on refunds and returns.
      </p>
      <p>
        The following terms are applicable for any products that you’ve
        purchased from us.
      </p>
      <h3>Interpretation and Definitions</h3>
      <h4>Interpretation</h4>
      <p>
        The words in which the initial letter is capitalised have meanings
        defined under the following conditions. The following definitions shall
        have the same meaning regardless of whether they appear in singular or
        in the plural.
      </p>
      <h4>Definitions</h4>
      <p>For the purposes of this Return and Refund Policy:</p>
      <p>
        “Company” (referred to as either "the Company", "We", "Us" or "Our" in
        this Agreement) refers to Gardening Bee
      </p>
      <p>“Goods” refers to the items offered for sale.</p>
      <p>“Orders” means a request by you to purchase goods from us.</p>
      <p>
        “Website” refers to gardeningbee.in, accessible from
        https://gardeningbee.in
      </p>
      <p>
        “You” means the individual accessing or using the service, or the
        company, or other legal entity on behalf of which such individual is
        accessing or using the service, as applicable.
      </p>
      <h3>Your Order Cancellation Rights</h3>
      <p>
        You are entitled to cancel your order within 1 day without giving any
        reason for doing so.
      </p>
      <p>
        The deadline for canceling an Order is 1 days from the date on which you
        ordered the goods or on which a third party you have appointed, who is
        not the carrier, takes possession of the product delivered.
      </p>
      <p>
        In order to exercise your right of cancellation, you must inform us of
        your decision by means of a clear statement. You can inform us of your
        decision by:
      </p>
      <p>By sending us an email: support@gardeningbee.in</p>
      <p>
        We will reimburse you no later than 14 days from the day on which we
        receive the returned Goods. We will use the same means of payment as you
        used for the Order, and you will not incur any fees for such
        reimbursement.
      </p>
      <h3>Conditions for Returns</h3>
      <p>
        In order for the Goods to be eligible for a return, please make sure
        that:
      </p>
      <p>
        The goods were purchased in the last 7 days
        <br />
        The goods are in the original packaging and are not tampred with
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>The following goods cannot be returned:</strong>
      </p>
      <p>
        The supply of goods is made to your specifications or clearly
        personalized.
        <br />
        The supply of goods which according to their nature are not suitable to
        be returned, deteriorate rapidly or where the date of expiry is over.
        <br />
        The supply of goods that are not suitable for return due to health
        protection or hygiene reasons and were unsealed after delivery.
        <br />
        The supply of goods which are, after delivery, according to their
        nature, inseparably mixed with other items.
      </p>
      <p>
        We reserve the right to refuse returns of any merchandise that does not
        meet the above return conditions at our sole discretion.
      </p>
      <h3>Returning Goods</h3>
      <p>Return should be done within 7 days of recieving the product</p>
      <p>
        We cannot be held responsible for goods damaged or lost in return
        shipment. Therefore, we recommend an insured and trackable mail service.
        We are unable to issue a refund without actual receipt of the goods or
        proof of received return delivery.
      </p>
      <h3>Refund Policy</h3>
      <p>Refund in your original payment mode in 4-5 working days</p>
      <h3>Contact Us</h3>
      <p>
        If you have any questions about our Returns and Refunds Policy, please
        contact us:
      </p>
      <p>
        Contact Details:
        <a href="mailto:support@gardeningbee.com">support@gardeningbee.in</a>
        and +91-8957849848
      </p>
    </div>
  );
};
