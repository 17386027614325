import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    modalHidden: false,
    address: {
      firstName: '',
      lastName: '',
      pinCode: '',
      mobileNumber: '',
      emailId: '',
      address: '',
      locality: '',
      landmark: '',
      city: '',
      state: '',
    },
  },
  reducers: {
    setModalHidden(state) {
      state.modalHidden = true;
    },
    setFirstName(state, action) {
      state.address.firstName = action.payload;
    },
    setLastName(state, action) {
      state.address.lastName = action.payload;
    },
    setEmailId(state, action) {
      state.address.emailId = action.payload;
    },
    setMobileNumber(state, action) {
      state.address.mobileNumber = action.payload;
    },
    setAddress(state, action) {
      state.address.address = action.payload;
    },
    setLocality(state, action) {
      state.address.locality = action.payload;
    },
    setLandmark(state, action) {
      state.address.landmark = action.payload;
    },
    setCity(state, action) {
      state.address.city = action.payload;
    },
    setState(state, action) {
      state.address.state = action.payload;
    },
    setPinCode(state, action) {
      state.address.pinCode = action.payload;
    },
    default(state) {
      return state;
    },
  },
});

export const {
  setFirstName,
  setLastName,
  setEmailId,
  setMobileNumber,
  setAddress,
  setLocality,
  setLandmark,
  setCity,
  setState,
  setPinCode,
  setModalHidden,
} = userSlice.actions;
export const userReducer = userSlice.reducer;
