import React, { useEffect, useState } from 'react';
import styles from '../Styles/AddressForm.module.scss';
import { Col, Form, Row } from 'react-bootstrap';
import { InvoiceContainer } from './InvoiceContainer/InvoiceContainer';
import { PaymentMethodFooter } from './PaymentMethodsFooter/PaymentMethodFooter';
import { useNavigate } from 'react-router';
import { useCart, useUser } from '../redux/hooks';
import axios from 'axios';
import { DEFAULT_RETRY_STRATEGY, kanpurPinCodes } from '../Constants/constants';
import axiosRetry from 'axios-retry';
import { CuponCode } from './CuponCode/CuponCode';
import { BackButton, PrimaryButton } from './Utility Components';
import { BeeFlying } from './Payment/BeeFlying';

const axiosInstance = axios.create();
axiosRetry(axiosInstance, DEFAULT_RETRY_STRATEGY);

const validateEmail = email => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const AddressForm = ({ setFlowState, flowState }) => {
  let navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState('');

  const [cityErrorMessage, setCityErrorMessage] = useState('');

  const {
    getTotalValue,
    getCart,
    addOrderId,
    orderId,
    cuponDetails,
  } = useCart();
  const {
    updateFirstName,
    updateLastName,
    updateEmailId,
    updateMobileNumber,
    updateAddress,
    updateLocality,
    updateLandmark,
    updateCity,
    updateState,
    updatePinCode,
    user,
  } = useUser();

  function transformProductData(productData) {
    return {
      items: productData.map(item => {
        return {
          id: item.Id,
          price: item.price,
          quantity: item.quantity,
          size: item.size,
        };
      }),
    };
  }

  function extractOrderId(message) {
    const orderId = message.match(/ORDER ID - ([\d-]+)/);
    return orderId ? orderId[1] : null;
  }

  const onContinueClick = async () => {
    console.log(hasFormChanged(), 'Form Status');
    console.log(orderId, 'Order Id');

    if (orderId && !hasFormChanged()) {
      return orderId;
    }

    try {
      setLoading(true);

      const modifiedCart = transformProductData(getCart);
      const formattedPayload = {
        cart: modifiedCart,
        value: { price: getTotalValue.discountedPrice },
        user: user.address,
      };

      const response = await axiosInstance.post(
        'https://gardening-bee-service.vercel.app/api/addOrder',
        formattedPayload
      );

      const orderId = extractOrderId(response.data);
      addOrderId(orderId);

      return orderId;
    } catch (error) {
      console.error('Error processing the order:', error);
      return null;
    }
  };

  const handleProceedToPayment = async () => {
    setFlowState(prev => ({ ...prev, addressCompleted: true }));

    const orderId = await onContinueClick();

    if (orderId) {
      setLoading(false);
      navigate('/pay');
    } else {
      console.error('Order ID was not received, cannot proceed to payment.');
    }
  };

  const validateZipCode = zipCode => {
    let isEligibleforDiscount = kanpurPinCodes.includes(zipCode);
    return cuponDetails?.discount === 30 && isEligibleforDiscount;
  };

  useEffect(() => {
    if (!flowState.cartCompleted) {
      navigate('/cart');
    }
  }, [flowState.cartCompleted, navigate]);

  useEffect(() => {
    if (user) {
      setFirstName(user.address.firstName || '');
      setLastName(user.address.lastName || '');
      setEmail(user.address.emailId || '');
      setPhone(user.address.mobileNumber || '');
      setAddress(user.address.address || '');
      setLocality(user.address.locality || '');
      setLandmark(user.address.landmark || '');
      setCity(user.address.city || '');
      setState(user.address.state || '');
      setZipCode(user.address.pinCode || '');
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      setInitialValues({
        firstName: user.address.firstName || '',
        lastName: user.address.lastName || '',
        email: user.address.emailId || '',
        phone: user.address.mobileNumber || '',
        address: user.address.address || '',
        locality: user.address.locality || '',
        landmark: user.address.landmark || '',
        city: user.address.city || '',
        state: user.address.state || '',
        zipCode: user.address.pinCode || '',
      });
    }
  }, []);

  const hasFormChanged = () => {
    console.log(initialValues, 'Initial Values');
    console.log(
      firstName,
      lastName,
      email,
      phone,
      address,
      locality,
      landmark,
      city,
      state,
      zipCode,
      'New Values'
    );
    return (
      firstName !== initialValues.firstName ||
      lastName !== initialValues.lastName ||
      email !== initialValues.email ||
      phone !== initialValues.phone ||
      address !== initialValues.address ||
      locality !== initialValues.locality ||
      landmark !== initialValues.landmark ||
      city !== initialValues.city ||
      state !== initialValues.state ||
      zipCode !== initialValues.zipCode
    );
  };

  const [initialValues, setInitialValues] = useState({});

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [locality, setLocality] = useState('');
  const [landmark, setLandmark] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipCode, setZipCode] = useState('');

  const handleFormSubmit = event => {
    event.preventDefault();
    if (!validateEmail(email)) {
      setEmailError('Please enter a valid email address.');
      return;
    }

    if (!validateZipCode(zipCode) && cuponDetails?.code === 'KANPUR') {
      setCityErrorMessage('Cupon code is only applicable for Kanpur');
      return;
    }
    setCityErrorMessage('');
    setEmailError('');
    updateFirstName(firstName);
    updateLastName(lastName);
    updateEmailId(email);
    updateMobileNumber(phone);
    updateAddress(address);
    updateLocality(locality);
    updateLandmark(landmark);
    updateCity(city);
    updateState(state);
    updatePinCode(zipCode);
    handleProceedToPayment();
  };

  return (
    <div className={styles.addressWrapper}>
      {loading && <BeeFlying />}
      <div className={styles.mobileHeader}>
        {/* <StoreClosedModal /> */}
        <div className="flex-row">
          <BackButton></BackButton>
          <div className="font-bold f-16">Cart</div>
        </div>
        <div className={styles.stepInfo}>Step 2/3</div>
      </div>
      <div className={styles.checkoutWrapper}>
        <div className={styles.leftSection}>
          <div className={styles.billingInformation}>
            <div className={styles.cartInfo}>Shipping Address</div>
          </div>

          <Form
            id="myform"
            onSubmit={event => {
              handleFormSubmit(event);
            }}
          >
            <Row>
              <Col xs={12} md={6} className="mb-3">
                <Form.Group controlId="formGridfname">
                  <Form.Label
                    style={{ fontSize: '13px', lineHeight: '20px' }}
                    className={styles.formLabel}
                  >
                    First Name
                  </Form.Label>
                  <Form.Control
                    value={firstName}
                    required
                    className="form-control-lg form-control-sm-md"
                    type="text"
                    placeholder="Enter first name"
                    onChange={e => updateFirstName(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col xs={12} md={6} className="mb-3">
                <Form.Group controlId="formGridlname">
                  <Form.Label
                    style={{ fontSize: '13px', lineHeight: '20px' }}
                    className={styles.formLabel}
                  >
                    Last Name
                  </Form.Label>
                  <Form.Control
                    required
                    value={lastName}
                    className="form-control-lg form-control-sm-md"
                    type="text"
                    placeholder="Enter last name"
                    onChange={e => updateLastName(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6} className="mb-4">
                <Form.Group controlId="formGridEmail">
                  <Form.Label style={{ fontSize: '13px', lineHeight: '20px' }}>
                    Email Address
                  </Form.Label>
                  <Form.Control
                    required
                    className="form-control-lg form-control-sm-md"
                    type="email"
                    placeholder="Enter email address"
                    onChange={e => updateEmailId(e.target.value)}
                    isInvalid={!!emailError}
                    value={email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {emailError}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col xs={12} md={6} className="mb-4">
                <Form.Group controlId="formGridphone">
                  <Form.Label style={{ fontSize: '13px', lineHeight: '20px' }}>
                    Mobile Number
                  </Form.Label>
                  <Form.Control
                    required
                    className="form-control-lg form-control-sm-md"
                    type="phone"
                    placeholder="Enter mobile number"
                    minLength={10}
                    maxLength={10}
                    value={phone}
                    onChange={e => updateMobileNumber(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Form.Group controlId="formGridAddress1" className="mb-3">
              <Form.Label
                style={{ fontSize: '13px', lineHeight: '20px' }}
                className={styles.formLabel}
              >
                Address
              </Form.Label>
              <Form.Control
                required
                value={address}
                className="form-control-lg form-control-sm-md"
                placeholder="Flat/House No, Building/Colony"
                onChange={e => updateAddress(e.target.value)}
              />
            </Form.Group>
            <Row>
              <Col xs={12} md={6} className="mb-3">
                <Form.Group controlId="locality">
                  <Form.Label
                    style={{ fontSize: '13px', lineHeight: '20px' }}
                    className={styles.formLabel}
                  >
                    Locality
                  </Form.Label>
                  <Form.Control
                    value={locality}
                    className="form-control-lg form-control-sm-md"
                    type="text"
                    placeholder="Eg: Gandhi Nagar, Mg Road"
                    onChange={e => updateLocality(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col xs={12} md={6} className="mb-3">
                <Form.Group controlId="landmark">
                  <Form.Label
                    style={{ fontSize: '13px', lineHeight: '20px' }}
                    className={styles.formLabel}
                  >
                    Landmark/Area
                  </Form.Label>
                  <Form.Control
                    value={landmark}
                    className="form-control-lg form-control-sm-md"
                    type="text"
                    placeholder="Eg: Near Crossing"
                    onChange={e => updateLandmark(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={4} className="mb-3">
                <Form.Group controlId="formGridCity">
                  <Form.Label
                    style={{ fontSize: '13px', lineHeight: '20px' }}
                    className={styles.formLabel}
                  >
                    City
                  </Form.Label>
                  <Form.Control
                    required
                    value={city}
                    className="form-control-lg form-control-sm-md"
                    onChange={e => updateCity(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col xs={12} md={4} className="mb-3">
                <Form.Group controlId="formGridState">
                  <Form.Label
                    style={{ fontSize: '13px', lineHeight: '20px' }}
                    className={styles.formLabel}
                  >
                    State
                  </Form.Label>
                  <Form.Select
                    value={state}
                    required
                    className="form-select-lg"
                    onChange={e => updateState(e.target.value)}
                  >
                    <option value="AP">Andhra Pradesh</option>
                    <option value="AR">Arunachal Pradesh</option>
                    <option value="AS">Assam</option>
                    <option value="BR">Bihar</option>
                    <option value="CT">Chhattisgarh</option>
                    <option value="GA">Gujarat</option>
                    <option value="HR">Haryana</option>
                    <option value="HP">Himachal Pradesh</option>
                    <option value="JK">Jammu and Kashmir</option>
                    <option value="GA">Goa</option>
                    <option value="JH">Jharkhand</option>
                    <option value="KA">Karnataka</option>
                    <option value="KL">Kerala</option>
                    <option value="MP">Madhya Pradesh</option>
                    <option value="MH">Maharashtra</option>
                    <option value="MN">Manipur</option>
                    <option value="ML">Meghalaya</option>
                    <option value="MZ">Mizoram</option>
                    <option value="NL">Nagaland</option>
                    <option value="OR">Odisha</option>
                    <option value="PB">Punjab</option>
                    <option value="RJ">Rajasthan</option>
                    <option value="SK">Sikkim</option>
                    <option value="TN">Tamil Nadu</option>
                    <option value="TG">Telangana</option>
                    <option value="TR">Tripura</option>
                    <option value="UT">Uttarakhand</option>
                    <option value="UP">Uttar Pradesh</option>
                    <option value="WB">West Bengal</option>
                    <option value="AN">Andaman and Nicobar Islands</option>
                    <option value="CH">Chandigarh</option>
                    <option value="DN">Dadra and Nagar Haveli</option>
                    <option value="DD">Daman and Diu</option>
                    <option value="DL">Delhi</option>
                    <option value="LD">Lakshadweep</option>
                    <option value="PY">Puducherry</option>
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col xs={12} md={4} className="mb-3">
                <Form.Group controlId="formGridZip">
                  <Form.Label
                    style={{ fontSize: '13px', lineHeight: '20px' }}
                    className={styles.formLabel}
                  >
                    Zip Code
                  </Form.Label>
                  <Form.Control
                    value={zipCode}
                    onChange={e => updatePinCode(e.target.value)}
                    required
                    maxLength={6}
                    isInvalid={!!cityErrorMessage}
                    minLength={6}
                    className="form-control-lg form-control-sm-md"
                  />
                  <Form.Control.Feedback type="invalid">
                    {cityErrorMessage}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </div>

        <div className={styles.rightSection} id="pricedetails">
          <CuponCode />
          <InvoiceContainer
            disable={loading}
            loading={loading}
            form="myform"
            type="submit"
          />
        </div>
      </div>
      <div className={styles.mobileCheckout}>
        <div className={styles.mobileLeft}>
          <div className={styles.price}>₹{getTotalValue.discountedPrice}</div>
          <a href="#pricedetails" className={styles.viewPriceDetails}>
            View price details
          </a>
        </div>
        <div className={styles.mobileRight}>
          <PrimaryButton
            form="myform"
            type="submit"
            label="Continue"
            loading={loading}
            disable={loading}
          ></PrimaryButton>
        </div>
      </div>
      <PaymentMethodFooter />
    </div>
  );
};
