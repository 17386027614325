import React from 'react';
import styles from '../../Styles/MobileMenu.module.scss';
import { useLocation, useNavigate } from 'react-router';
import { useCart } from '../../redux/hooks/useCart';
export const MobileMenu = () => {
  let location = useLocation();
  let navigate = useNavigate();
  const { getCart } = useCart();
  const isProductPage = /^\/product\/\d+/.test(location.pathname);
  return (
    <>
      {!isProductPage ? (
        <div className={styles.menuWrapper}>
          <div
            onClick={() => {
              navigate('/');
            }}
            className={`${styles.menuItem} ${
              location.pathname === '/' ? styles.activeClassNav : null
            }`}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              color="#4d4d4d"
              className={styles.menuImage}
            >
              <path
                d="M21.0195 7.85152L13.0517 1.82587C12.7615 1.61411 12.4115 1.5 12.0522 1.5C11.6929 1.5 11.3429 1.61411 11.0527 1.82587L2.93328 7.861C2.72378 8.01486 2.55363 8.21609 2.43672 8.44825C2.31982 8.68042 2.25947 8.93694 2.26061 9.19687V19.8365C2.25674 20.1739 2.3559 20.5044 2.54484 20.7839C2.60387 20.8743 2.67389 20.9571 2.75327 21.0303C2.90597 21.1871 3.08869 21.3115 3.29053 21.3962C3.49237 21.4808 3.7092 21.5239 3.92806 21.5229H8.3904C8.58519 21.5244 8.77825 21.4863 8.95789 21.4109C9.13753 21.3356 9.30002 21.2246 9.43553 21.0847C9.57103 20.9447 9.67675 20.7788 9.74626 20.5968C9.81577 20.4148 9.84765 20.2206 9.83995 20.026L9.78311 14.9383C9.78561 14.656 9.89951 14.3861 10.1 14.1874C10.3005 13.9887 10.5714 13.8772 10.8537 13.8772H13.0517C13.3331 13.8772 13.603 13.989 13.802 14.188C14.001 14.387 14.1128 14.6569 14.1128 14.9383V20.026C14.1128 20.4079 14.2645 20.7742 14.5346 21.0443C14.8047 21.3144 15.171 21.4661 15.5529 21.4661H20.0721C20.5143 21.4661 20.9384 21.2904 21.2511 20.9777C21.5638 20.665 21.7395 20.2409 21.7395 19.7986V9.17792C21.733 8.91563 21.6644 8.65863 21.5392 8.42804C21.414 8.19746 21.2359 7.99987 21.0195 7.85152Z"
                fill="currentColor"
              ></path>
            </svg>
            <div>Home</div>
          </div>

          <div
            onClick={() => {
              navigate('/cart');
            }}
            className={`${styles.menuItem} ${
              location.pathname === '/cart' ? styles.activeClassNav : null
            }`}
          >
            <div className={styles.topIcon}>{getCart.length}</div>
            <svg
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              alt="Bag"
              className={styles.menuImage}
              color={location.pathname === '/cart' ? '#20695c' : '#4d4d4d'}
            >
              <path
                d="M17.5303 2C17.7769 2 18.0091 2.1161 18.157 2.31336L20.9105 5.985C20.9338 6.01451 20.955 6.04575 20.974 6.07848C20.9829 6.09427 20.9915 6.11041 20.9995 6.12681C21.0067 6.14109 21.0133 6.15592 21.0196 6.17098C21.0243 6.18292 21.0289 6.19482 21.0331 6.20682C21.0407 6.22728 21.0472 6.24826 21.0528 6.2696C21.0698 6.33447 21.0787 6.40189 21.0787 6.47005V19.3733C21.0787 20.824 19.9027 22 18.452 22H5.54873C4.09802 22 2.922 20.824 2.922 19.3733V6.47005C2.922 6.36611 2.94267 6.26389 2.98211 6.16909C2.98795 6.15455 2.99438 6.14034 3.00121 6.12636C3.02283 6.08242 3.04873 6.03994 3.07868 6L3.027 6.07799C3.02812 6.07606 3.02925 6.07413 3.03039 6.07221L3.07868 6L5.84366 2.31336C5.99161 2.1161 6.2238 2 6.47038 2H17.5303ZM19.511 7.253H4.488L4.48882 19.3733C4.48882 19.8806 4.84525 20.3047 5.32135 20.4087L5.43324 20.427L5.54873 20.4332H18.452C19.0373 20.4332 19.5119 19.9586 19.5119 19.3733L19.511 7.253ZM15.687 9.37327C16.1196 9.37327 16.4704 9.72402 16.4704 10.1567C16.4704 12.6254 14.4691 14.6267 12.0003 14.6267C9.5316 14.6267 7.53029 12.6254 7.53029 10.1567C7.53029 9.72402 7.88104 9.37327 8.3137 9.37327C8.74637 9.37327 9.09711 9.72402 9.09711 10.1567C9.09711 11.7601 10.3969 13.0599 12.0003 13.0599C13.6037 13.0599 14.9036 11.7601 14.9036 10.1567C14.9036 9.72402 15.2543 9.37327 15.687 9.37327ZM17.1386 3.56682H6.86117L5.27 5.686H18.728L17.1386 3.56682Z"
                fill="currentColor"
              ></path>
            </svg>
            <div>Cart</div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
